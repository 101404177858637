import React from "react";
import { FooterScreen } from "../index/FooterScreen";
import { HeaderScreen } from "../index/HeaderScreen";

export const IndexRutaScreen = ()=>{
  const styles = {
    backgroundImage: "url(images/melipeuco2.jpg)",
  }
  return (
    <>
      <HeaderScreen />
      <div className="colorlib-introduction" style={{backgroundImage:styles.backgroundImage}}>
      
        <div className="overlay"></div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-9 header-introduction" >
              <div className="intro-box animate-box">
                <h1> Traslado Aeropuerto Temuco - Melipeuco</h1>
                <p> El viaje que desees dentro de la novena región lo podrás realizar. Contamos con choferes y rutas específicas para el traslado que prefieras.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container rounded roundeInfo">
        <div className="container infoMapa" >
        <div className="row">
          <div className="col-xs-12 itemDiv">  
            <h2 className="itemTitle">
              <i className="fas fa-map-signs iconItem"></i> Nuestra Ruta
            </h2>
            <p>Traslado seguro y eficas</p>
          </div>
        
          <div className="col-xs-12 col-md-6">
            <iframe 
              className="divIframe"
              title="mapsMelipeuco"
              src="https://www.google.com/maps/embed?pb=!1m24!1m8!1m3!1d1138293.3352796335!2d-72.26781987544263!3d-39.03711046292518!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x9614dec58f5d709b%3A0xfdcdf026ce8a1f31!2sAeropuerto%20Araucan%C3%ADa%20-%20Longitudinal%20Sur%20km%20692%2C%20Freire!3m2!1d-38.9271653!2d-72.6468532!4m5!1s0x961369e728acae0d%3A0x4a535ad82861a570!2sMelipeuco%2C%20Araucan%C3%ADa!3m2!1d-38.8522015!2d-71.6932032!5e0!3m2!1ses-419!2scl!4v1633314163114!5m2!1ses-419!2scl" 
              style={{border:'0',width:'100%', height:'500px', overflow:'auto'}} 
              loading="lazy">
            </iframe>
          </div>
          <div
            className="col-xs-12 col-md-6"
          >
            <div className="itemMapa">
              <h3>Seguridad 360</h3>
              <p>Trabajamos día con día para ofrecer los viajes más completos y seguros del mercado. Reforzamos tu confianza con las medidas n.º 1 del país.</p>
            </div>
            <div className="itemMapa">
              <h3>Toma de temperatura</h3>
              <p>Nuestras políticas de abordaje incluyen el chequeo de cada pasajero.</p>
            </div>
            <div className="itemMapa">
              <h3>Cubrebocas</h3>
              <p>Protégete a ti, a tus compañeros de viaje y a tus seres queridos todo el viaje.</p>

              <a 
                href="/traslado/2"
                type="submit" 
                className="btn btn-primary btn-block" 
                style={{marginTop:'20px'}}
                >
                  <i className="fas fa-shopping-cart" style={{marginRight: '15px'}}></i>

                   Comprar Traslado
                </a>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div className="container infoIcons">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="post-container">                
              <div className="post-thumb">
                <span>
                <i className="fas fa-route iconItem" ></i>
                </span>
              </div>
              <div className="post-content">
                <h3 className="post-title">Viajes Directos</h3>
                <p>Tu tiempo es oro y nuestros viajes son óptimos para garantizarlo</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="post-container">                
              <div className="post-thumb">
                <span>
                  <i className="far fa-credit-card iconItem"></i>
                </span>
              </div>
              <div className="post-content">
                <h3 className="post-title"> 
                  Pago Online
                </h3>
                <p>Con reservas digitales tu seguridad y la de nuestros viajes es un hecho</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="post-container">                
              <div className="post-thumb">
                <span>
                  <i className="far fa-smile-beam iconItem"></i>
                </span>
              </div>
              <div className="post-content">
                <h3 className="post-title"> 
                  LLega a tiempo
                </h3>
                <p>Te garantizamos estar en el lugar de destino lo antes posible!</p>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className="container infoData">
          <div className="row">
            <div className="col-xs-12 divItem">  
              <h2 className="itemTitle">
                <i className="fas fa-shuttle-van iconItem" aria-hidden="true"></i> Viaje Simple
              </h2>
            </div>    
            <div className="col-md-12">
              <p>
                  Disfrute de un viaje sin problemas y sin estrés desde su llegada al aeropuerto temuco 
                  hasta Melipeuco con un servicio seguro y eficaz. Viaje directamente hacia la hermosa 
                  ciudad de Melipeuco en un vehículo cómodo, contamos con una flota de vehículos sprinter 
                  los cuales están adaptados para que usted pueda realizar su traslado lo más placentero posible.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr/>

      <div className="container infoData">
          <div className="row">
            <div className="col-xs-12 item" style={{textAlign:'center', fontSize: '20px',fontweight: 400}}>  
              <h2 className="itemTitle">
                <i className="far fa-calendar-alt iconItem"></i> Itenerario semanal
              </h2>
            </div>
            
            <div className="col-xs-12 col-md-3" >  
                <div 
                  style={{ 
                    fontSize: '20px',
                    fontweight: 400, 
                    backgroundColor:'#FCE9D8',
                    marginRight: '20px',
                    marginBottom: '10px',
                    borderRadius: '5%',
                    padding:'10px',
                    height: '270px'
                  }}
                >
                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Rutas</span><br></br>
                    <span style={{fontWeight:'500'}}>Aeropuerto - Melipeuco</span>
                  </p>

                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Dias</span><br></br>
                    <span style={{fontWeight:'500'}}>Lunes - Viernes </span>
                  </p>
                  <span>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Horario</span><br></br>
                    <ul>
                      <li>13:50 PM</li>
                      <li>17:00 PM</li>
                      <li>18:30 PM</li>
                    </ul>
                  </span>
                </div>
              </div>

              <div className="col-xs-12 col-md-3">  
                <div 
                  style={{ 
                    fontSize: '20px',
                    fontweight: 400, 
                    backgroundColor:'#F5F5F5',
                    marginRight: '20px',
                    marginBottom: '10px',
                    borderRadius: '5%',
                    padding:'10px',
                    height: '270px'
                  }}
                >
                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Rutas</span><br></br>
                    <span style={{fontWeight:'500'}}>Aeropuerto - Melipeuco</span>
                  </p>

                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Dias</span><br></br>
                    <span style={{fontWeight:'500'}}>Sabado - Domingo </span>
                  </p>
                  <span>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Horario</span><br></br>
                    <ul>
                      <li>11:00 AM</li>
                      <li>13:00 PM</li>
                      <li>15:00 PM</li>
                      <li>18:00 PM</li>
                    </ul>
                  </span>
                </div>
              </div>
              
              <div className="col-xs-12 col-md-3" >  
                <div 
                  style={{ 
                    fontSize: '20px',
                    fontweight: 400, 
                    backgroundColor:'#FCE9D8',
                    marginRight: '20px',
                    marginBottom: '10px',
                    borderRadius: '5%',
                    padding:'10px',
                    height: '270px'
                  }}
                >
                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Rutas</span><br></br>
                    <span style={{fontWeight:'500'}}>Melipeuco - Aeropuerto</span>
                  </p>

                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Dias</span><br></br>
                    <span style={{fontWeight:'500'}}>Lunes - Viernes </span>
                  </p>
                  <span>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Horario</span><br></br>
                    <ul>
                      <li>13:50 PM</li>
                      <li>17:00 PM</li>
                      <li>18:30 PM</li>
                    </ul>
                  </span>
                </div>
              </div>
              
              <div className="col-xs-12 col-md-3">  
                <div 
                  style={{ 
                    fontSize: '20px',
                    fontweight: 400, 
                    backgroundColor:'#F5F5F5',
                    marginRight: '20px',
                    borderRadius: '5%',
                    padding:'10px',
                    height: '270px'
                  }}
                >
                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Rutas</span><br></br>
                    <span style={{fontWeight:'500'}}>Melipeuco - Aeropuerto</span>
                  </p>

                  <p>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Dias</span><br></br>
                    <span style={{fontWeight:'500'}}>Sabado - Domingo </span>
                  </p>
                  <span>
                    <span style={{fontSize: '12px',lineHeight: '14px'}}>Horario</span><br></br>
                    <ul>
                      <li>11:00 AM</li>
                      <li>13:00 PM</li>
                      <li>15:00 PM</li>
                      <li>18:00 PM</li>
                    </ul>
                  </span>
                </div>
              </div>
        </div>        
    </div>
    <div className="container infoCompra">
      <div className="row">
         <div className="col-xs-12 divNosVamos">  
              <h2 className="itemTitle">
                 ¿Nos Vamos?
              </h2>
        </div>
        <div className="col-xs-12 divCompraTraslado">  
            <a href="/traslado/2">
              <i className="fas fa-shopping-cart"></i>   Ir a Comprar Traslado
            </a>
        </div>
      </div>
    </div>

    </div>
    <FooterScreen/>


    </>
  )
}