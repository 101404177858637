import React from 'react';
import { FooterScreen } from '../index/FooterScreen';
import { Navbar } from './Navbar';
export const ErrorCompra = ()=>{
  return (
    <>
      <Navbar />
      <div className="container infoData" style={{height:"600px"}}>
          <div className="col-xs-12 divError">  
            <h2 className="itemTitle">Pago Erroneo</h2>
            <div className="divPago">
              <div className="divIcon">
                <i className="far fa-times-circle errorCircle"></i>
              </div>
            </div>
            <hr></hr>
            <div className="msgExito" >
              <p>Al parecer hubo un problema en el pago, Intentanlo de nuevo</p>
            </div>
            <hr></hr>
            <div className="msgConfirmacion">
              <h2>Importante</h2>
              <p>Todos los medios de pago se hacen a traves del portal FLOW, de igual forma envianos un 
                correo en caso que haya existido un problema con el pago. </p>
            </div>
          </div>
      </div>
      <FooterScreen />
    </>
  )
}