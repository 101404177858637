import React from "react";

export const Navbar = ()=>{
  return (
    <>
      <nav className="navbar navbar-inverse">
        <div className="container-fluid">
          <div className="navbar-header">
            <div className="navbar-brand ">
            <a className="colorlib-logo"  href="/" style={{color: '#FF6E1D'}}>
									<span style={{fontWeight: 'bold',color: '#FF6E1D', fontsize:'22px', marginTop:'10px'}}>
									<img src="/images/logochico.png" alt="dddd" width="40px"/>
										Tour
										Huellas
									</span>
								</a>
            </div>
          </div>
          <ul className="nav navbar-nav">
            {/*      <li><a href="#">Home</a></li> */}
            <li>
              <a href="/melipeuco">Temuco - Melipeuco</a>
            </li>
            <li className="active">
              <a href="/traslado/2">Compra Traslado</a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}