import React from 'react';
import { FooterScreen } from '../index/FooterScreen';

export const ExitoCompra = ()=>{
  return (
    <>
      <nav className="navbar navbar-inverse">
      <div className="container-fluid">
        <div className="navbar-header">
          <div className="navbar-brand ">
            <a className="colorlib-logo" href="/">
              <i className="fas fa-paw" aria-hidden="true" style={{fontSize: "35px"}}></i>
              <span>Tour</span><span>Huellas</span>
            </a>
          </div>
        </div>
        <ul className="nav navbar-nav">
     {/*      <li><a href="#">Home</a></li> */}
          <li><a href="/melipeuco">Temuco - Melipeuco</a></li>
          <li className="active" ><a href="/traslado/2">Compra Traslado</a></li>
        </ul>
      </div>
    </nav>

      <div className="container infoData" style={{height:"600px"}}>
          <div className="col-xs-12 divExito">  
            <h2 className="itemTitle">Pago exitoso</h2>
            <div className="divPago">
              <div className="divIcon">
                <i className="far fa-check-circle exitoCircle"></i>
              </div>
            </div>
            <hr></hr>
            <div className="msgExito" >
              <p>Nos contactaremos contigo dentro de las proximas horas, para coordinar tu dia y hora de llegada. </p>
            </div>
            <hr></hr>
            <div className="msgConfirmacion">
              <h2>¿No te llegó ningún correo?</h2>
              <p>Revisa tu bandeja de correo no deseado</p>
            </div>
          </div>
      </div>
      <FooterScreen />
    </>
  )
}