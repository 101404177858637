import React from 'react'

export const IntroductionScreen = () => {
  return (
    <div className="colorlib-introduction" style={{backgroundImage:  "url('images/paisaje.jpg')"}} data-stellar-background-ratio="0.5">
    <div className="overlay"></div>
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-md-push-3">
          <div className="intro-box animate-box">
            <h2> <i className="fas fa-route" id="iconFas" aria-hidden="true"></i> Rutas Personalizadas</h2>
            <p> El viaje que desees dentro de la novena región lo podrás realizar. Contamos con choferes y rutas especificas para el traslado que prefieras.</p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
