import React from 'react'

export const ViajesEntry = (viaje) => {

  console.log(viaje);

  return (

    <div className="col-md-4 animate-box">
      <div className="dish-wrap">
   
          <div
            className="dish-img"
            style={{
              backgroundImage:
               viaje.img
            }}
          ></div>
          <div className="desc">
            <h2>
              <a href="#!">
                {viaje.titulo}
              </a>
            </h2>
          </div>
      
      </div>
    </div>
  )
}
