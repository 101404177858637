import React from "react";
import { ViajesEntry } from "./ViajesEntry";

export const MenuViajesScreen = () => {
	const viajes = [
		{
			id: 0,
			img: "url(images/furgon1.jpg)",
			titulo: "El trayecto que desees",
		},
		{
			id: 1,
			img: "url(images/furgon2.jpg)",
			titulo: "Minibuses sprinter",
		},
		{
			id: 2,
			img: "url(images/furgon3.png)",
			titulo: "Angol",
		},
	];

	return (
		<div className="colorlib-menu">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center animate-box intro-heading">
						<span className="icon">
							<i className="fas fa-map-marked-alt"></i>
						</span>
						<h2>Nuestro Vehiculos</h2>
						<p>
							Modernos vehículos sprinter año 2019-2021 con capacidad de hasta
							22 pasajeros todos incluyen aire acondicionado y las normas
							requeridas por las autoridades.
						</p>
					</div>
				</div>
				<div className="row">
					{viajes.map((viaje) => (
						<ViajesEntry key={viaje.id} {...viaje} />
					))}
				</div>
				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center animate-box intro-heading">
						<p>
							Nuestro servicio incluye el recojo del vehículo desde su domicilio 
							hasta el lugar donde el cliente desea recibirlo.
						</p>
					</div>
				</div>
			</div>
			
		</div>
		
	);
};
