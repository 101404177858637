import React from 'react'

export const IntroScreen = () => {
  return (
    <div className="colorlib-intro">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6 text-center">
          <div className="intro animate-box">
            <span className="icon">
              <i className="icon-map4"></i>
            </span>
            <h2>¿Nos quieres visitar?</h2>

            <a
            
              href="https://www.google.com/maps/place/Patricio+Lynch+156,+Temuco,+Araucan%C3%ADa/@-38.7342941,-72.5957787,17z/data=!3m1!4b1!4m5!3m4!1s0x9614d3e844be0f5d:0x6154c2841690be3f!8m2!3d-38.7342941!4d-72.59359?hl=es-419"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#ffffff" }}
            >
              Chile - Temuco Lynch 156
            </a>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 text-center">
          <div className="intro animate-box">
            <span className="icon">
              <i className="icon-clock4"></i>
            </span>
            <h2>Horario</h2>
            <p>Lunes - Domingo</p>
            <span>8am - 9pm</span>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 text-center">
          <div className="intro animate-box">
            <span className="icon">
              <i className="fab fa-whatsapp"></i>
            </span>
            <h2>Telefonos</h2>
            <a
              href="https://api.whatsapp.com/send?phone=56922486947"
              target="_blank"
              rel="noreferrer"
              className="py-2 d-block"
              style={{ color: "#ffffff" }}
            >
              +569 9 2248 6947{" "}
            </a>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 text-center">
          <div className="intro animate-box">
            <span className="icon">
              <i className="icon-envelope"></i>
            </span>
            <h2>Correo</h2>
            <p>
              <a href="mailto:contacto@tourhuellas.cl"  rel="noreferrer">
                contacto@tourhuellas.cl
              </a>
              <br />
   
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
