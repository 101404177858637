import React from "react";
import { FormContacto } from "../contacto/FormContacto";

export const ContactoScreen = () => {
	return (
		<div
			className="colorlib-menu"
			style={{ backgroundColor: "rgb(247, 247, 247, 1)" }}
		>
			<div className="container"  id="contacto">
				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center animate-box intro-heading">
						<span className="icon">
							<i 
                className="far fa-envelope"
                style={{ backgroundColor: "rgb(247, 247, 247, 1)" }}
              />
						</span>
						<h2>Contactanos</h2>
            <FormContacto />
						
					</div>
				</div>
				<div className="row"></div>
				<div className="row">
					<div className="col-md-6 col-md-offset-3 text-center animate-box intro-heading">
						<p>
							Te prometemos en conctactanos contigo en menos de 24 hrs!
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
