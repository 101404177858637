import React from 'react'

export const HeaderScreen = () => {
  return (
    <header>
      <div className="d-flex justify-content-start">
        <div className="colorlib-navbar-brand" style={{ marginLeft: "5%" }}>
          <a className="colorlib-logo" href="/" style={{ color: "#FF6E1D" }}>
            <span
              style={{ fontWeight: "bold", color: "#FF6E1D", fontsize: "22px" }}
            >
              <img src="images/logochico.png" alt="dddd" width="50px" />
              Tour Huellas
            </span>
          </a>
        </div>
        <a href="#/">
          <i></i>
        </a>
      </div>
    </header>
  );
}
