import React from 'react'

export const AboutScreen = () => {
  return (
    <div className="colorlib-about colorlib-light-grey">
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="about-desc">
              <div className="col-md-12 col-md-offset-0 animate-box intro-heading">
                <span>Tour - Huellas</span>
                <h2>
                  Hemos realizado diferentes tipos de
                  viajes desde el 2005
                </h2>
              </div>
              <div className="col-md-12 animate-box">
                <p>
                  Durante estos años Tour-Huellas se
                  ha dedicado al servicio de
                  transporte de pasajeros, recorriendo
                  la mayor parte de la IX región de la araucania
                  llevando asi a nuestros pasajeros a los
                  mejores lugares de turismo y de
                  entretenimiento, ademas contar con
                  todos nuestros vehiculos del año y
                  nuestro equipo de conductores
                  esperando dar la mejor experiencia
                  de viaje.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-6 animate-box">
              <div
                className="about-img"
                style={{
                  backgroundImage:
                    "url(images/mercedes-bus.jpg)",
                }}
              ></div>
            </div>
            <div className="col-md-6 animate-box">
              <div
                className="about-img about-img-2"
                style={{
                  backgroundImage:
                    "url(images/mercedes-bus2.jpg)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
