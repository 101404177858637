import React from "react";
import { FooterScreen } from "../index/FooterScreen";
import { Navbar } from "./Navbar";

export const CompraTraslado = ()=>{

  return(
    <>
    <Navbar />
    <div className="container infoData">
    <div className="row-flex divCompraTrasladoPago">

      <div className="col-md-4 col-sm-6 col-xs-12 divMetodoPago">
        <div className="tituloPago">
          <h2>Método de pago</h2>
          <div className="hijo center-block" style={{ backgroundImage: "url(/images/flow.png)"}}>
            
          </div>
          <div className="alert alert-info alertPago">
              <b>Importante: </b> 
              <span>No olvides de ingresar tu correo al momento de realizar el pago en Flow, 
              es la unica manera que tenemos para contactarte.</span>
          </div>

        </div>
      </div>
      <div className="col-md-7 col-sm-6 col-xs-12 divResumen">
        <div className="mt-4 tituloPago">
          <h2>Resumen Compra</h2>
          <label>Contenido.</label>
          <table className="table" style={{width: "100%"}}>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a href="/acrossandes">
                    Traslado Aeropuerto Temuco - Melipeuco<br></br>
                    Ida y Vuelta
                  </a>
                </td>
                <td>CLP $60.000</td>
              </tr>
              <tr>
                <td></td>
                <td>TOTAL</td>
                <td>CLP $60.000</td>
              </tr>
              <tr>
                <td></td>
                <td>CARGO POR SERVICIO</td>
                <td>CLP $2.800</td>
              </tr>
              <tr>
                  <td></td>
                  <td>TOTAL + CARGO POR SERVICIO</td>
                  <td>CLP $62.800</td>
              </tr>
              </tbody>
            </table>

            <div className="col-xs-12 divBtnTraslado">  
              <a href='https://www.flow.cl/btn.php?token=ewlx5lv' target="_blank" rel="noreferrer">
                <i className="fas fa-shopping-cart"></i> Comprar Traslado
              </a>
            </div>
        </div>
      </div>

    </div>
    

  </div>

      <FooterScreen/>
    </>
  )
}