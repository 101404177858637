import React from "react";

export const AsideEntry = (content) => {
	console.log(content);
	return (
	
				<li
					style={{
						backgroundImage: content.image,
					}}
				>
					<div className="overlay"></div>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-6 col-sm-12 col-xs-12 col-md-offset-3 slider-text">
								<div className="slider-text-inner text-center">
									<div className="desc">
										<span className="icon">
											{
												(content.id === 1)
													?
													<i className="fas fa-shuttle-van"></i>
												:
													<i className="fas fa-biking"></i>
											}
											
										</span>
										<h1>{content.title}</h1>
										<p>{content.text}</p>
										{
											(content.id === 1) &&
											<p>
												<a
													href="#contacto"
													className="btn btn-primary btn-lg btn-learn"
												>
													{content.pie}
												</a>
											</p>
										}
										{
											(content.id === 0) &&
											<p>
												<a
													href="/melipeuco"
													className="btn btn-primary btn-lg btn-learn"
												>
													{content.pie}
												</a>
											</p>
										}
										<div className="desc2"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>

	);
};
