import React from "react";

export const FooterScreen = () => {
	return (
		<footer>
			<div id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-pb-sm">
							<h2>Transporte Huellas</h2>
							<p style={{ width: "44%" }}>
								"Nuestro destino de viaje nunca es un lugar,
								sino una nueva forma de ver las cosas".
							</p>
						</div>
						<div className="col-md-4 col-pb-sm">
							<div className="ftco-footer-widget mb-4">
								<h2 className="ftco-heading-2">
									Informacion contacto
								</h2>
								<ul className="list-unstyled">
									<li>
										<a
											href="https://www.google.com/maps/place/Patricio+Lynch+156,+Temuco,+Araucan%C3%ADa/@-38.7342941,-72.5957787,17z/data=!3m1!4b1!4m5!3m4!1s0x9614d3e844be0f5d:0x6154c2841690be3f!8m2!3d-38.7342941!4d-72.59359?hl=es-419"
											target="_blank"
                      rel="noreferrer"
											className="py-2 d-block"
										>
											Chile - Temuco Lynch 156
										</a>
									</li>
									<li>
										<a
											href="https://api.whatsapp.com/send?phone=56922486947"
											target="_blank"
                      rel="noreferrer"
											className="py-2 d-block"
										>
											+569 9 2248 6947{" "}
										</a>
									</li>
									<li>
										<a
											href="mailto:contacto@tourhuellas.cl"
											className="py-2 d-block"
										>
											contacto@tourhuellas.cl
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 text-center"></div>
					</div>
				</div>
			</div>
		</footer>
	);
};
