import React from "react";
import { AboutScreen } from "../index/AboutScreen";
import { AsideScreen } from "../index/AsideScreen";
import { FooterScreen } from "../index/FooterScreen";
import { HeaderScreen } from "../index/HeaderScreen";
import { IntroductionScreen } from "../index/IntroductionScreen";
import { IntroScreen } from "../index/IntroScreen";

import { MenuViajesScreen } from "../index/MenuViajesScreen";
import {ContactoScreen} from '../index/ContactoScreen';

export const IndexScreen = () => {
	return (
		<div>

			<div id="colorlib-page">
				<HeaderScreen />
				<AsideScreen />
				<IntroScreen />
				<AboutScreen />
				<IntroductionScreen />
	   
				<MenuViajesScreen />
				<ContactoScreen />
				
{/* 
				<MenuTrayectosScreen />
				 */}

				<FooterScreen />
				<div className="goto-here"></div>
			</div>
			
		


		</div>
	);
};
