import { createStore,combineReducers,compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { mapReducer } from "../reducers/mapReducer";


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const reducers = combineReducers({
  mapas: mapReducer,
});



export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk)
  )
)