import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { IndexScreen } from '../components/layout/IndexScreen';
import { LayoutScreen } from '../components/layout/LayoutScreen';
import { CompraTraslado } from '../components/rutas/CompraTraslado';
import { ErrorCompra } from '../components/rutas/ErrorCompra';
import { ExitoCompra } from '../components/rutas/ExitoCompra';
import { IndexRutaScreen } from '../components/rutas/IndexRutaScreen';





export const AppRouter = ()=>{
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/login" component={LayoutScreen} />
          <Route exact path="/" component={IndexScreen} />
          <Route exact path="/melipeuco" component={IndexRutaScreen} />
          <Route exact path="/traslado/:id" component={CompraTraslado} />
          <Route exact path="/pagoexitoso" component={ExitoCompra}/>
          <Route exact path="/errorpago" component={ErrorCompra} />
        

          {/* <Redirect to="/"/>  */}
        </Switch>
        
      </div>
    </Router>
  );
}