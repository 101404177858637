
import axios from 'axios';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useForm } from '../../hook/useForm';
import ReCAPTCHA from "react-google-recaptcha";


export const FormContacto = () => {
  const [values, handleInputChange ] = useForm({ email:'',telefono:'', mensaje:''});
  const { email,telefono, mensaje } = values;
 
  const [msgContacto, setMsgContacto] = useState(false);
  
  const [errores, setErrores] =useState({
    email:'',
    mensaje: ''
  });

  const {mensaje:errorMensaje, email:errorEmail } = errores;

  useEffect(() => {
    if(values.email){
      setErrores({email:'',mensaje:errorMensaje}); 
    }
    if(values.mensaje){
      setErrores({mensaje:'',email:errorEmail});
    } 

  }, [values,errorMensaje,errorEmail])

  
  const [captchaValido, cambiarCaptchaValido] = useState(null);

	const captcha = useRef(null);

	const onChange = () => {
		if(captcha.current.getValue()){
			cambiarCaptchaValido(true);
		}
	}



  const envioFormulario = async (e)=>{ 
    e.preventDefault();
    if(captcha.current.getValue()){
			console.log('El usuario no es un robot');
			//cambiarUsuarioValido(true);
			cambiarCaptchaValido(true);


      const {data} = await axios.post('https://api.cumbresanramon.cl/api/ingresoContacto',{ email,telefono, mensaje, tipoContacto:2 });
      const {ok, errores} = data;
  
      (ok) &&
      setMsgContacto(true); 

      if(errores){
        setErrores(errores)
      }
    

		} else {
			console.log('Por favor acepta el captcha');
			//cambiarUsuarioValido(false);
			cambiarCaptchaValido(false);
		}

    
  }
  return (
    <form>
      
      {
        (msgContacto) &&
        <div className="alert alert-success" role="alert">
          <a href="/#" className="close" data-dismiss="alert" aria-label="close">×</a>
          <h4 className="alert-heading">Mensaje enviado!</h4>
          <p>Te queremos agradecer por tu mensaje enviado, pronto nos comunicaremos contigo para planificar tus trayecto.</p>
          <hr />
          <p className="mb-0">"Nuestro destino de viaje nunca es un lugar, sino una nueva forma de ver las cosas".</p>
        </div>
      }
     
     {captchaValido === false && <div className="alert alert-danger" role="alert">
          <a href="/#" className="close" data-dismiss="alert" aria-label="close">×</a>
          <h4 className="alert-heading">Error!</h4>
          <p>Debes rellenar todos los campos.</p>
      </div>}
     


      <div className="form-group">

        <input
          type="email"
          name="email"
          value={email}
          onChange={handleInputChange}
          className="form-control is-invalid"
          placeholder="Escribe tu correo acá"
          style={{ borderRadius: "20px",  border: (errores.email) ? 'solid 1px #ff12125c' : ''}}
          required
        />
        {
          (errores) &&
            <div className="invalid-feedback" style={{color:'#ff00009c', textAlign:'left'}}>
              {errores.email}
            </div>
        }
      </div>
      <div className="form-group">
        <input
          type="number"
          name="telefono"
          value={telefono}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Tu numero de contacto acá"
          style={{ borderRadius: "20px" }}
          required
        />
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          name="mensaje"
          value={mensaje}
          onChange={handleInputChange}
          rows="7"
          placeholder="Cuentanos tu trayecto mas detallado...."
          style={{ borderRadius: "20px",  border: (errores.mensaje) ? 'solid 1px #ff12125c' : '' }}
          required
          
        />
        {
          (errores) &&
            <div className="invalid-feedback" style={{color:'#ff00009c', textAlign:'left'}}>
              {errores.mensaje}
            </div>
        }
      </div>
      <div className="recaptcha">
							<ReCAPTCHA
								ref={captcha}
								sitekey="6LeUZpccAAAAACBFIMofGmlXpXMGzyqF9jIbqbG8"
								onChange={onChange}
							/>
		  </div>
			{captchaValido === false && <div className="error-captcha">Por favor acepta el captcha</div>}
      <button 
        type="submit" 
        className="btn btn-primary btn-block" 
        onClick={envioFormulario}
        style={{marginTop:'20px'}}
      >
        Enviar
      </button>
    </form>
  )
}
