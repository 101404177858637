
import { types } from '../types/types';
const initialState = {
  mapas:[],
  active:null
}
export const mapReducer = (state = initialState, action) => {
 switch(action.type){
    case types.mapsLoad:
      return{
      ...state,
      mapas:[...action.payload]
     }
    case types.updatedMap:
      return{
        ...state,
        mapas: state.mapas.map(m=> (m.id ===action.payload.id) ? {...action.payload} : {...m})
      } 
    default: 
      return state;
 }
}
