import React from "react";
import { AsideEntry } from "./AsideEntry";

export const AsideScreen = () => {
  const contentLi = [
    {
      id:0,
      image: 'url(images/biciMelipeuco.jpeg)',
      title: 'Across Andes',
      text : 'Te estaremos esperando en el aeropuerto y te iremos a dejar a la hermosa ciudad de melipeuco.',
      pie : 'Reserva acá'
    },
    {
      id:1,
      image: 'url(images/portada1.jpg)',
      title: 'Servicio Transporte Privado IX región',
      text : 'Transporte para todos tipo de viajes - Disposición de viajes por dia y horas',
      pie : 'Realiza una reserva'
    },
    {
      id:2,
      image: 'url(images/portada2.jpg)',
      title: 'Al lugar que quieras y donde quieras',
      text : 'No olvides en realizar cualquier consulta acerca tu viaje y nosotros nos encargaremos.',
      pie : '¿Donde quieres ir?'
    },
    {
      id:3,
      image: 'url(images/mercedez3.jpg)',
      title: 'Transporte hacia el aeropuerto.',
      text : 'Te vamos a buscar y dejar al aeropuerto a cualquier hora de llegada.',
      pie : '¿Donde nos necesitas?'
    }
]

	return (
		<aside id="colorlib-hero">
      <div className="flexslider">
				<ul className="slides">
        {
        contentLi.map((content) => (
          <AsideEntry 
            key={content.id}
            {...content}
          />
			  )) 
      }

        </ul>
      </div>
   
		</aside>
	);
};
